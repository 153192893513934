<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">

        <div class="va-card">
          <div class="row">
            <div class="col-lg-12">
              <div class="dash_card_body pb-0">
                <div class="header_section">
                  <h4 class="menu_header">Orders</h4>
                  <div class="right-btnGroup" v-if="adminRoles.includes(user.role.id)">
                    <h1>Export Orders</h1>
                    <div class="btn-toolbar">
                      <button class="btn btn-purple" :disabled="searchingType == 'csv' ? true : false" style="margin-right:10px;" @click="exportOrder('csv')">
                        <span v-if="searchingType == 'csv'"><span v-html="searchspinner"></span></span> CSV</button>
                      <button class="btn btn-purple" @click="exportOrder('xlsx')" :disabled="searchingType =='xlsx' ? true : false">
                        <span v-if="searchingType == 'xlsx'"><span v-html="searchspinner"></span></span> Excel
                      </button>

                      <!-- <button class="btn btn-purple" style="display: hidden;" id="play_audio" @click="playAudio">
                      </button> -->

                      <!-- audio to be auto-played -->

                      <!-- end -->


                    </div>
                  </div>
                </div>
                <div class="search_section mb-0">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group has-search">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="orderSearch" @Keyup="searchOrders()" class="form-control"
                          placeholder="Search">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mr-2" @click="searchOrders('search')">
                          <span v-if="searchingType == 'search'"><span v-html="searchspinner"></span></span> Search
                        </button>
                      </div>
                    </div>


                    <div class="col-md-2">
                      <div class="form-group has-search">

                        <button class="w-100 btn btn-dgrey" @click="clearSearch">
                          <span v-if="searchingType == 'clear'"><span v-html="searchspinner"></span></span> Clear Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="row">
                        <div class="col-md-3 pad-r-0">
                          <div class="">
                            <Datepicker placeholder="Start Date" v-model="start" utc autoApply
                              :format="customFormatter"></Datepicker>
                          </div>

                        </div>
                        <div class="col-md-3 pad-r-0">
                          <div class="">
                            <Datepicker placeholder="End Date" v-model="end" autoApply utc :format="customFormatter"
                              :minDate="minDate"></Datepicker>
                          </div>
                        </div>
                        <div class="col-md-2 pad-r-0">

                          <Multiselect v-model="storeSearch" @change="searchOrders()" :close-on-select="true"
                            :options="strsoptions" :custom-label="name" :searchable="true"
                            :placeholder="'Stores'" label="name" track-by="name"></Multiselect>
                        </div>

                        <div class="col-md-2 pad-r-0">
                          <select class="form-control" v-model="searchChannel" @change="searchOrders()">
                            <option value="">Channel</option>
                            <option value="FOODIE">FOODIE</option>
                            <option v-for="channel in channels" :key="channel.id" :value="channel.channel_name">
                              {{channel.channel_name}}</option>
                          </select>
                        </div>
                        <div class="col-md-2 ">
                          <select class="form-control" v-model="posStatus" @change="searchOrders()">
                            <option value="">Pos status</option>
                            <option value="1">Posted</option>
                            <option value="0">Failed To post</option>

                          </select>
                        </div>

                      </div>
                    </div>
                  </div>


                  <!--Status menu start-->
                  <div class="St-menu">
                    <div class="status_btn_group"></div>
                    <button :class="[{'active': statusType == ''},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('')">
                      <span ><strong>All</strong></span>
                    </button>
                    <button :class="[{'active': statusType == 'Pending'},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('Pending')">
                      <span style="color:#EC850C"><strong>Pending</strong> </span>
                    </button>
                    <button :class="[{'active': statusType == 'Failed'},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('Failed')">
                      <span style="color:#A81414;" ><strong>Failed</strong></span>
                    </button>
                    <button :class="[{'active': statusType == 'Completed'},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('Completed')">
                      <span style="color:#1B8B20"><strong>Completed</strong></span>
                    </button>
                  </div>
                  <!--Status menu End-->
                </div>
              </div>
              <hr>
            </div>
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Order Ref</th>
                        <th scope="col">Store</th>
                        <th scope="col">Channel</th>
                        <th scope="col">User</th>
                        <th scope="col"> Total </th>
                        <th scope="col">Payment Details</th>
                        <th scope="col">Order Status</th>
                        <th scope="col" v-if="pesapalConfig.post_to_pos == 1">POS</th>
                        <th scope="col">Delivery Details</th>
                        <th scope="col" v-if="appConfigs.allow_printing || appConfigs.allow_mark_asready">Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="(order,oindx) in  orders" :key="oindx">
                        <td>
                          <a class="title_text" href="javascript:void(0)" data-toggle="modal"
                            :data-target="'#orderDetail_'+order.id"> {{order.channel_display_id}}</a>
                        </td>
                        <td>
                          {{order.store}} <br>
                          <span class="mt-4  text-muted"><small>{{formatDate(order.created_at,1)}}</small></span>
                          <div v-if="order.fargo ==1">
                            <span class="mt-2 pending">
                              <strong>Future Order Date:</strong>
                              {{formatDate(order.fargo_on,2)}}
                            </span>
                          </div>
                        </td>
                        <td>
                          {{order.channel}}
                        </td>

                        <td>
                          <span v-if="order.customer_name">{{order.customer_name}}</span>
                          <div v-else>
                            <span>N/A</span> <br>
                            <span v-if="order.room_no || order.table_no">
                              <small>In house Order</small>
                            </span>
                          </div> <br />
                        </td>
                        <td class="td-price">
                          {{order.currency}} {{order.total_price > 0 ? formatPrice(order.total_price)
                            :formatPrice(order.sub_total_price + order.shipping_cost)}}
                        </td>
                        <td>
                          <strong>Status: </strong> <span :class="[{'active': order.payment_status.toLowerCase() === 'completed'},{'disabled':order.payment_status.toLowerCase() === 'failed'},{'pending': order.payment_status.toLowerCase() === '' || order.payment_status.toLowerCase()  == 'pending' || order.payment_status.toLowerCase()  == 'placed'}]">
                            <strong>{{order.payment_status ? order.payment_status : 'PENDING'}}</strong>
                          </span> <br>
                          <strong>Method: </strong> <span>{{ order.payment_method }}</span>
                          <br><br>
                          <!-- <span v-if="order.channel =='FOODIE' && (!order.payment_status || order.payment_status.toLowerCase()  == 'pending' || order.payment_status.toLowerCase()  == 'placed')">
                            <button class="btn btn-sm btn-default custom_btn  t_btn custon_orange"
                              @click="checkStatus(order.id)"><span v-if="statusSpinner == order.id"> <i
                              class="fa fa-spinner fa-spin"></i> Checking..</span><span v-else>Check
                              Status</span>
                            </button>
                          </span> -->
                          <!-- button to manually complete a payment -->
                          <span v-if="(order.channel =='FOODIE' && user.role.id == 1 ) && (!order.payment_status || order.payment_status.toLowerCase()  == 'failed')">
                            <button class="btn btn-sm mt-1 btn-default t_btn custom_btn custon_orange" data-toggle="modal" :data-target="`#manualcompletion_${order.id}`">
                              <span>Manually Complete</span>
                            </button>
                          </span>

                          <!-- modal for to enter method -->
                          <div class="modal fade" :id="`manualcompletion_${order.id}`" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h2 class="modal-title" id="exampleModalLongTitle">Manually Completing Order (#{{ order.channel_display_id }})</h2>
                                  <button type="button" class="close" :id="`close_manualcompletion_${order.id}`" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1">Payment Method</label>
                                    <select class="form-control" v-model="paymentmethod[order.id]">
                                      <option value="[]">--Select Method--</option>
                                      <option value="Mpesa">Mpesa</option>
                                      <option value="Visa">Visa</option>
                                      <option value="AirtelMoney">Airtel Money</option>
                                      <option value="VOUCHER">Voucher</option>
                                    </select>
                                  </div>
                                  <div class="form-group">
                                    <label for="exampleInputPassword1">Payment ID</label>
                                    <input type="text" class="form-control" v-model="paymentId[order.id]">
                                  </div>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Dismiss</button>
                                  <button type="button" class="btn btn-success" @click="manualyComplete(order.id)" >
                                    <span v-if="errorLoading == order.id"><i class="fa fa-spinner fa-spin"></i> </span>  Complete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>



                        </td>
                        <td>
                          <span v-if="order.status">
                            {{order.status}}
                          </span>
                        </td>
                        <td v-if="pesapalConfig.post_to_pos == 1">
                          <span v-if="order.pos_status">
                            <span class="active"><strong>Posted Successfull</strong></span> <br>
                            <span><b>POS ID:</b>{{order.pos_data}}</span><br>
                            <span><b>Status:</b>{{order.pos_data_status}}</span> <br>
                            <span><b>Balance:</b> {{formatPrice(order.pos_data_balance)}}</span><br>
                          </span>

                          <span v-else-if="((order.payment_status == 'COMPLETED' || pesapalConfig.post_all_orders) && order.fargo ==0) && !order.pos_past_timeout">
                            <!-- pos repost -->
                            <div v-if="pesapalConfig.auto_posting">
                              <button @click="posRePost(order.id,true)" class="btn btn-success custom_btn t_btn btn-sm">
                                <span v-if="spinner == order.id" v-html="searchspinner"></span><span v-else>POS Repost</span>
                              </button>
                            </div>
                            <div v-else>
                              <div>
                                <button type="button" @click="posRePost(order.id,true)" class="btn btn-success btn-sm mr-1 t_btn">
                                  <span v-if="spinner == order.id" v-html="searchspinner"></span>Accept</button>
                                <button type="button" class="btn custon_orange btn-sm t_btn" data-toggle="modal" :data-target="`#denyPos_${order.id}`">Decline</button>

                                <!-- modal for reason -->
                                <div class="modal fade" :id="`denyPos_${order.id}`" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                  <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">Decline Posting Order (#{{ order.channel_display_id }})</h5>
                                        <button type="button" class="close" :id="`closedenyPos_${order.id}`" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <div class="form-group">
                                          <label for="exampleInputPassword1">Reason</label>
                                          <select class="form-control" v-model="declineReasons[order.id]">
                                            <option value="">--Select Reason--</option>
                                            <option>Out of Stock</option>
                                            <option>Technical issues</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary t_btn" data-dismiss="modal">Dismiss</button>
                                        <button type="button" class="btn custon_orange t_btn" @click="posRePost(order.id,false)" >Decline</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- end modal -->
                              </div>
                            </div>
                            <!-- end pos repost -->
                            <br />
                          </span>
                          <span v-else>
                            <div v-if="order.status.toLowerCase() == 'denied'">
                              <strong class="disabled">Cancelled</strong> <br>
                              {{ order.error_reason }} <br>
                              <small v-if="order.action_user"> <strong>Cancelled By:</strong>{{ order.action_user }}</small>
                            </div>
                            <div v-else>
                              <span class="disabled"><strong>Not Posted</strong> </span>
                            </div>

                          </span>
                        </td>
                        <td>
                          <span>
                            {{order.shipping_method ? order.shipping_method === 'flat' ? 'flatrate' :
                              order.shipping_method : 'N/A' }}

                            <br> <br>
                            <span v-if="order.shipping_method && order.shipping_method == 'storepickup' && order.type !='PICKUP'">
                              <strong>{{ order.type }}</strong>
                            </span>
                            <span v-if="order.shipping_code">
                              <br><br>
                              <strong>Code: </strong>{{ order.shipping_code }}
                            </span>
                            <!-- swyft delivery method -->
                            <div v-if="order.shipping_method == 'swyft'">
                              <div v-if="order.shipping_response.update_status">
                                <span v-if="order.shipping_response.update_status.status_name"> <strong>Status:</strong> {{ order.shipping_response.update_status.status_name }}</span> <br>
                                <strong>Updated: </strong>{{ formatDate(order.shipping_response.update_status.time_stamp,1) }} <br>
                                <div v-if="order.shipping_response.update_status.rider.name">
                                  <strong>Name: </strong> {{ order.shipping_response.update_status.rider.name }} <br>
                                  <strong>Phone: </strong> {{ order.shipping_response.update_status.rider.phone }}
                                </div>
                                <div v-if="order.shipping_response.update_status.tracking_url && order.shipping_response.update_status.status_name.toLowerCase() !='delivered'">
                                  <a :href="order.shipping_response.update_status.tracking_url" target="_blank">
                                    <button class="btn btn-sm btn-default custom_btn custon_orange t_btn" > Track Order</button>
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div v-if="(order.payment_status.toLowerCase() === 'completed' && order.shipping_code =='' ) && order.fargo == 0 && (order.shipping_method.toLowerCase() != 'storepickup' && order.shipping_method !='' )">
                              <br><br>
                              <button class="btn btn-sm btn-default custom_btn custon_orange t_btn"
                                @click="postTodeliver(order.id)"><span v-if="deliverySpinner == order.id"> <i
                                class="fa fa-spinner fa-spin"></i> Posting..</span><span v-else>Post To {{order.shipping_method.toLowerCase()  }}</span></button>
                            </div>


                          </span>
                        </td>
                        <td >
                          <!-- appConfigs.allow_printing || appConfigs.allow_mark_asready -->
                          <button v-if="appConfigs.allow_printing == 1" type="button" class="btn custon_orange btn-sm t_btn mr-1"  @click="printOrder(order.id)" > <span v-if="printing == order.id"><i class="fa fa-spinner fa-spin"></i></span> Print Order</button>
                          <button v-if="appConfigs.allow_mark_asready" type="button" @click="markAsReady(order.id)" :class="['btn btn-sm',{'btn-success':!order.delivery_status},{'btn-purple':order.delivery_status}]" v-bind:disabled="order.delivery_status ? true :false" > <span v-if="isReadyspinner == order.id"><i class="fa-spinner fa-spin"></i></span> {{ order.delivery_status ? 'Rider Notifed':'Ready for Pickup' }} </button>

                        </td>
                        <!--  -->
                        <!-- Modal -->
                        <div class="modal order_modal right" :id="'orderDetail_'+order.id"
                          aria-labelledby="myModalLabel2">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <!-- {{order}} -->
                              <div class="modal-header order_modal_header">
                                <h3 class="modal-title">Order <b>{{order.channel_display_id}}</b> </h3>
                                <button type="button" class="close button_close" data-dismiss="modal"
                                  aria-label="Close"><span aria-hidden="true">&times;</span></button>
                              </div>

                              <div class="modal-body">
                                <div class="padd">
                                  <div class="sec customer-D bd-b">
                                    <h4 class="c-title">Customer Details</h4>
                                    <span v-if="order.customer_name"><strong>Name: </strong>{{order.customer_name}}
                                      <br></span>
                                    <span v-if="order.customer_phone"><strong>Phone: </strong>{{order.customer_phone}}
                                      <br></span>
                                    <span v-if="order.customer_phone_code"> <strong>Phone Code:</strong>
                                      {{order.customer_phone_code}} <br></span>

                                    <span v-if="order.map_delivery_address"><strong>Address:
                                    </strong>{{order.map_delivery_address}} <br></span>
                                    <span v-if="order.delivery_address"><strong>More details:
                                    </strong>{{order.delivery_address}} <br></span>

                                    <span v-if="order.table_no"><strong>Table: </strong> {{ order.table_no }}<br></span>
                                    <span v-if="order.room_no"><strong>Room: </strong>{{order.room_no}} <br></span>
                                  </div>

                                  <div class="sec bd-b">
                                    <h4 class="c-title">Order Details</h4>

                                    <table class="order-table" width="100%" cellspacing="0" cellpadding="0">
                                      <tbody>
                                        <tr v-for="(item,itemindx) in order.items" :key="item.id">
                                          <td width="70%" valign="top"><b>{{itemindx + 1}}. {{item.name}}</b>

                                            <div class="indent" v-if="item.sub_items.length > 0">
                                              <ul>
                                                <li v-for="sub_item in item.sub_items" :key="sub_item">
                                                  <b>{{sub_item.modifier_group}}:</b> <span>{{sub_item.name}} </span>
                                                </li>
                                              </ul>
                                            </div>
                                            <div>
                                              <span v-if="item.special_instructions !==''">
                                                <b>Instructions: </b>
                                                <span>{{item.special_instructions}}</span></span>
                                            </div>

                                          </td>
                                          <td class="text-right" width="10%" valign="top">
                                            <b>x {{item.quantity}}</b>
                                            <div class="indent_children" v-if="item.sub_items.length > 0">
                                              <ul >
                                                <li  v-for="sub_item in item.sub_items" :key="sub_item">
                                                  <span>
                                                    x   {{ getSubItemPrice(sub_item,item).quantity ? getSubItemPrice(sub_item,item).quantity :sub_item.quantity}}
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </td>
                                          <td class="text-right" width="30%" valign="top">
                                            <b>{{formatPrice(item.price_total)}}</b>
                                            <div class="indent_children" v-if="item.sub_items.length > 0">
                                              <ul >
                                                <li  v-for="sub_item in item.sub_items" :key="sub_item">
                                                  <span>
                                                    <b>{{formatPrice(getSubItemPrice(sub_item,item).price_total !=undefined? getSubItemPrice(sub_item,item).price_total : sub_item.price_total)}}</b>
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr v-if="order.special_instructions">
                                          <td colspan="3"><hr style="width: 100%;"/></td>
                                        </tr>
                                        <tr v-if="order.special_instructions">
                                          <td colspan="3">
                                            <span>
                                              <strong>Order Note: </strong>
                                              {{order.special_instructions}}
                                              <br/>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="3"><hr style="width: 100%;"/></td>
                                        </tr>
                                        <tr>
                                          <td class="text-right" colspan="2" valign="top" align="right">Order Amount:
                                          </td>
                                          <td class="text-right" valign="top"> <b>{{order.currency}}
                                            {{formatPrice(order.sub_total_price)}}</b></td>
                                        </tr>
                                        <tr>
                                          <td class="text-right" colspan="2" valign="top" align="right">Delivery Cost:
                                          </td>
                                          <td class="text-right" valign="top"><span
                                                                                v-if="order.shipping_cost"><b>{{formatPrice(order.shipping_cost)}}</b></span>
                                            <span v-else><b>0.00</b></span></td>
                                        </tr>
                                        <tr>
                                          <td class="text-right" colspan="2" valign="top" align="right">Order Total: </td>
                                          <td class="text-right" valign="top"> <b>{{order.currency}} {{order.total_price > 0 ?
                                            formatPrice(order.total_price) : formatPrice(order.sub_total_price +
                                              order.shipping_cost)}}</b></td>
                                        </tr>
                                      </tfoot>
                                    </table>

                                  </div>

                                  <div class="sec bd-b payment-D">

                                    <h4 class="c-title">Payment Details</h4>
                                    <div>
                                      <span  v-if="order.payment_method"><b>Method:</b>{{order.payment_method}} </span><br>
                                      <span
                                        v-if="order.payment_status"><b>Status:</b>{{order.payment_status}}</span><br>
                                      <span  v-if="order.payment_id"><strong>ID:
                                      </strong>{{order.payment_id}}</span> <br>
                                      <span  v-if="order.payment_code"><strong>Code:
                                      </strong>{{order.payment_code}}</span> <br>
                                      <span  v-if="order.user_id != 0"><strong>Updated By:
                                      </strong>{{order.user}}</span>
                                    </div>

                                    <div v-if="order.vouchers &&  order.vouchers.length > 0">

                                      <hr>
                                      <h4 class="c-title">Voucher Redemption Details</h4>
                                      <div></div>
                                      <span v-for="voucher in order.vouchers" :key="voucher.voucher_code">
                                        <b>Code: </b> {{voucher.voucher_code}} &nbsp; <b> Amount: </b> {{voucher.amount}}
                                        <br>
                                      </span> <br>
                                    </div>
                                    <hr>
                                    <div class="px-1 mt-2 mb-2" v-if="order.channel">

                                      <span class="p-title">Order Channel: </span>
                                      <span  v-if="order.channel">{{order.channel}}</span>
                                    </div>
                                    <hr>
                                    <div class="pos-data px-1 mt-1">
                                      <h4 class="c-title">POS Details</h4>
                                      <div v-if="order.pos_status">
                                        <b>POS ID:</b>{{order.pos_data}}<br>
                                        <b>Status:</b>{{order.pos_data_status}}<br>
                                        <b>Balance:</b> {{formatPrice(order.pos_data_balance)}}<br>
                                      </div>
                                      <div v-if="!order.pos_status">
                                        <p>{{order.pos_data}}</p> <br>
                                        <hr>
                                        <div class="form-group">
                                          <span class="py-3" style="font-weight: 500;font-size: 14px;">POS Details</span>
                                          <div class="row">
                                            <div class="col-md-8">
                                              <input type="text" class="form-control" v-model="itemPosId">
                                            </div>
                                            <div class="col-md-4">
                                              <button class="btn btn-orange"
                                                @click="getItem(order)">Search</button>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-if="orderItem.length > 0">
                                          <div v-for="(orderi,i) in orderItem" :key="i">
                                            <span><b>Item Name</b>{{orderi.name}}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="sec bd-b">

                                    <!-- <div class="mark_as_ready mb-2" v-if="!order.delivery_status">
                                      <h4>Mark as ready for Pickup</h4> <br>
                                      <button class="btn btn-success btn-sm t_btn" @click="markAsReady(order.id)"><span
                                        v-if="isReadyspinner == order.id"></span>Mark as Ready</button>
                                    </div> -->
                                    <div class="order_delay mt-1" style="display:none">
                                      <h4>Set Delay</h4> <br>
                                      <input type="text" class="form-control col-md-7 mr-2" v-model="prepTime"> <button
                                        class="btn btn-success" @click="createDelay(order.id)"><span
                                        v-if="spinner == order.id"></span>submit</button>
                                    </div>
                                    <div class="delivery_details mt-1" v-if="order.shipping_method">

                                      <h4 class="c-title">Delivery Details</h4>
                                      <span> <b>Method:</b>{{order.shipping_method}}<br></span>
                                    </div>



                                  </div>
                                  <div class="sec bd-b" v-if="order.notes !=''">
                                    <div class="mt-1">
                                      <h4 class="c-title">Additional info</h4>
                                      <p>{{ order.notes }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </tr>
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td :colspan="(appConfigs.allow_printing || appConfigs.allow_mark_asready) ? '10' : '9'">
                          <div id="pagination" v-if="totalPages > 1">
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                  <option :value="100">100</option>
                                </select>
                              </li>

                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page"
                              @pagechanged="onPageChange" />

                          </div>

                        </td>
                      </tr>
                    </tfoot>
                  </table>

                </div>
                <div v-if="loading" class="loading-cover">
                  <div class="loader"> </div>
                </div>

              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Howl} from 'howler';
import moment from 'moment';
import Pusher from 'pusher-js';
import pako from 'pako'
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import Pagination from '@/pages/pagination/pagination.vue';
import Multiselect from '@vueform/multiselect';
import { useToast } from "vue-toastification";
const toast = useToast();
import audion from '../../../../public/audios/orderbeep.mp3'
export default {
    components: {
        Pagination,
        Multiselect
    },
    data() {
        return {
            statusType: '',
            orders: [],
            start: moment().subtract(30,'d').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
            orderSearch: '',
            stores: [],
            orderItem: [],
            searchingType: '',
            loading: false,
            page: 1,
            orderDelay: '',
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: this.$store.state.ordersRecordPerPage,
            debounce: null,
            storeSearch: '',
            menuItems: [],
            orderStatus: '',
            paymentStatus: '',
            productSearch: '',
            strsoptions: [],
            productOptions: [{
              "value": '',
              "name": "All Products"
            }],
            itemPosId: '',
            enterpageno: '',
            spinner: '',
            exportType: '',
            exportData: [],
            channels: [],
            isReadyspinner: false,
            prepTime: 0,
            searchChannel: '',
            deliverySpinner: null,
            statusSpinner: false,
            searchspinner: "<i class='fa fa-spinner fa-spin '></i>",
            declineReasons:[],
            paymentmethod:[],
            paymentId:[],
            errorLoading: false,
            showPosDetail: 1,
            autoRefresh: false,
            hasPlayed: false,
            audioRef: '',
            printing:false,
            sysConfigs: [],
            Roles: {
                superAdmin: 1,
                admin:2,
                storeManager: 3,
                user:4,
                callCenter: 5,
                financeAdmin:6
            },
            posStatus: ''
        }
    },
    mounted: async function () {
        this.getOrders();
        this.getBranches();
        this.getItems();
        this.getChannels();
        await this.getAppConfigs();
        this.subscribe();
        // this.setupStream();
    },
    computed: {
        minDate() {
            return this.start;
        }
    },
    methods: {
      async getAppConfigs(){
            try{
                const url = `${this.dashBaseUrl}/configs/${this.merchantID}`;
                const response = await this.axios.get(url, this.apiRequestConfig())
                if (response.status === 200 ) {
                    this.sysConfigs = response.data.data.app_settings;
                    return this.sysConfigs;
                    // console.log("system configs",this.sysConfigs)
                }
            }catch(err){
                //
            }

            //
      },
      decompressData(data){
        const orderBytes = atob(data)
        const charData    = orderBytes.split('').map((x)=>{return x.charCodeAt(0);});
        const resultdata  = pako.inflate(new Uint8Array(charData));
        return String.fromCharCode.apply(null, new Uint16Array(resultdata));
      },
      subscribe () {
        const notificationEnabled =this.appConfigs.enable_order_alerts
        if(notificationEnabled){
          const pusherKey = this.PusherConfig.key
          const pusherCluster = this.PusherConfig.cluster
          const pusher = new Pusher(pusherKey, { cluster: pusherCluster })
          pusher.subscribe('new-order')
          pusher.bind(`${this.merchantID}_order`, data => {
            const stores = this.user.stores
            const userStores = [];
            // const foodieChannels = ['PDQ-POS','FOODIE','MINIAPP','KIOSK']
            this.autoRefresh = true
            if(stores !=undefined && stores.length >  0){
              for(let i=0; i < stores.length; i++){
                userStores.push(stores[i].store_id)
              }
            }
            const order = JSON.parse(this.decompressData(data.order))
            const orderExist = this.orders.filter((x) => {
              return x.id == order.id
            })
            if(orderExist.length == 0 && order !=undefined){
              console.log("order",order)
                this.orders = [order, ...this.orders]
            }
            if(this.user.role.id == this.Roles.storeManager && userStores.includes(data.branch_id)){
              this.playHowlerAudio()
            }
          })
        }

    },
    playHowlerAudio(){
      const trackAudio = audion//  "https://soundbible.com/mp3/sms-alert-2-daniel_simon.mp3"
      const sound = new Howl({
        src: [trackAudio],
        autoplay: true,
      });
      sound.play()

    },
      playAudio(){
        const trackAudio = "https://soundbible.com/mp3/sms-alert-2-daniel_simon.mp3"
        const audio = document.createElement("AUDIO")
        audio.src = trackAudio
        document.body.appendChild(audio)
        audio.onpause = () => audio.remove();
        audio.play();
      },
      async printOrder(orderId){
        try{
          this.printing = orderId
          const url = `${this.baseUrl}/print-order/${orderId}`
          const res = await this.axios.post(url,{},this.apiRequestConfig())
          if(res.status === 200){
            this.printing = false
          }
        }catch(error){
          this.printing = false
          toast.error("unable to print")
        }



      },
      getSubItemPrice(subitem,item){
        const subitemObj = {}
        if (subitem.addon_type.toLowerCase() == "extra") {
          const totalQty = subitem.quantity * item.quantity
          const totalPrice = subitem.price_per * totalQty
          subitemObj['quantity'] = totalQty
          subitemObj['price_total'] = totalPrice
        }else{
          subitemObj['quantity'] =  subitem.quantity
          subitemObj['price_total'] = subitem.price_total
        }
        return subitemObj
      },
        async manualyComplete(orderid){
          this.errorLoading = orderid
          try {
            const payload ={
              'payment_method': this.paymentmethod[orderid],
              'payment_id': this.paymentId[orderid]
            }
            const url =`${this.dashBaseUrl}/manually-update-payment/${orderid}/${this.merchantID}`
            const res = await this.axios.post(url,payload,this.apiRequestConfig())
            if(res.status === 200){
              this.errorLoading = false
              document.getElementById(`close_manualcompletion_${orderid}`).click()
              toast.success("order manually completed successfully")
            }
          } catch (error) {
            this.errorLoading = false
            document.getElementById(`close_manualcompletion_${orderid}`).click()
            if(error.response && error.response.status === 400){
              let errorMsg = ''
              const reserrors = error.response.data.errors;
              for(let err=0; err < reserrors.length; err++){
                errorMsg +=reserrors[err]+'\n'
              }
              toast.error(errorMsg)
            }else if(error.response && error.response.status === 401 ){
              this.$router.push({
                      name: 'login'
              });
            }else{
              toast.error("unable to manully complete payment")
            }

          }
        },
        async postTodeliver(orderId){
          try{
            this.deliverySpinner = orderId;
            const url = `${this.dashBaseUrl}/delivery-respost/${orderId}`;
            const response = await this.axios.post(url,{},this.apiRequestConfig())
            if(response.status == 200){
              if(response.data.status == true){
                this.deliverySpinner = null;
                toast.success(response.data.msg)
              }else{
                this.deliverySpinner = null;
                toast.error(response.data.msg)
              }
              this.getOrders();
            }else{
              //
              this.deliverySpinner = null;
              toast.error("Unable to process your request")
            }
          }catch(error){
            if (error.response && error.response.status === 401) {
                  this.$router.push({
                      name: 'login'
                  });
              }
            //
          }

        },
        filterPaymentStatus(type){
          this.paymentStatus = type
          this.statusType = type
          this.getOrders();
        },
        clearSearch() {
            this.orderSearch = ''
            this.searchingType = 'clear';
            this.productSearch = '';
            this.paymentStatus = '';
            this.storeSearch = '';
            this.start = '';
            this.end = '';
            this.orderStatus = '';
            this.exportType = '';
            this.getOrders();
        },
        customFormatter(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        exportOrder(type) {
            this.searchingType = type;
            this.exportType = type;
            this.getOrders();
        },
        async getChannels() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "/third-party-setting?merchant_id=" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.channels = response.data.data
                }
            });
        },
        async createDelay(orderId) {
            this.spinner = orderId
            try {
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const payload = {
                    "merchant_id": parseInt(this.merchantID),
                    "prep_time": parseInt(this.prepTime)
                }
                const url = `${this.baseUrl}/orders/${orderId}/update-prep-time`
                await this.axios.patch(url, payload, config)
                this.spinner = ''
            } catch (error) {
                //
            }
        },
        async markAsReady(orderId) {
            this.isReadyspinner = orderId;
            try {
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const payload = {
                    "merchant_id": parseInt(this.merchantID),
                }
                const url = `${this.baseUrl}/orders/${orderId}/mark-ready-for-pickup`
               const response =  await this.axios.post(url, payload, config)
               if(response.status ==200){
                toast.success("Ready For Pickup")
               }
               console.log('marks as ready response',response)
                this.isReadyspinner = false;
            } catch (error) {
              toast.error(error.response.data.message)
              this.isReadyspinner = false;
            }

        },
        searchOrders(type = '') {
            if (type !== '') {
                this.searchingType = type;
            }
            this.page = 1
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getOrders();
            }, 1000)
        },
        getOrders() {
            this.loading = this.autoRefresh ? false : true;
            this.statusType = this.paymentStatus
            let url = `${this.baseUrl}/orders`;
            url = new URL(url);
            url.searchParams.set('merchant_id', this.merchantID);
            if (this.exportType === '') {
                url.searchParams.set('page', this.page);
                url.searchParams.set('size', this.recordsPerPage);
            }
            if (this.searchChannel !== '') {
                url.searchParams.set('channel', this.searchChannel);
            }
            if (this.orderSearch !== '') {
                url.searchParams.set('query', this.orderSearch);
            }
            if (this.storeSearch !== '') {
                url.searchParams.set('branch', this.storeSearch);
            }
            if (this.orderSearch == '') {
                url.searchParams.set('start_at', moment(this.start).format('YYYY-MM-DD'));
                url.searchParams.set('end_at',  moment(this.end).format('YYYY-MM-DD'));
            }
            if (this.orderStatus !== '') {
                url.searchParams.set('order_status', this.orderStatus);
            }
            if (this.paymentStatus !== '') {
                this.paymentStatus = this.paymentStatus !='Pending' ? this.paymentStatus : ''
                url.searchParams.set('payment_status', this.paymentStatus);
            }
            if (this.productSearch !== '') {
                url.searchParams.set('item', this.productSearch)
            }
            if(this.posStatus !==''){
              url.searchParams.set('pos_status',this.posStatus)
            }
            if (this.exportType !== '' ) {
                this.loading = false;
                url.searchParams.set('format', this.exportType)
            }

            if (this.user.role.id === 3) {
                url.searchParams.set('user_id', this.user.id)
            }
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.showPosDetail = this.pesapalConfig.post_to_pos
                    // if exporting dont load data
                    if (this.exportType !== '') {
                        this.exportData = response.data;

                        if (this.exportType === 'csv') {
                            const fileName = Date.now() + '_order.csv';
                            this.exportCsv(this.exportData, fileName);
                            this.exportType = '';
                        } else {
                            const Porders = response.data.data;
                            const orderDetails = [];
                            for (const order of Object.values(Porders)) {
                                const orderExportData  = {
                                    'Order Ref': order.channel_display_id,
                                    'Order Date': this.formatDate(order.created_at, 1),
                                    'Store': order.store,
                                    'Agent': order.user,
                                    'Payment Method': order.payment_method,
                                    'Payment Status': order.payment_status,
                                    'Order Status': order.status,
                                    'Name': order.customer_name,
                                    'Email': order.customer_email,
                                    'Phone': order.customer_phone,
                                    'Delivery Address': order.map_delivery_address,
                                    'Delivery Details': order.shipping_method,
                                    'Delivery Cost': order.shipping_cost,
                                    'Channel': order.channel,
                                    'Order Amount': order.total_price,
                                }
                                if(this.showPosDetail == 1){
                                  orderExportData['POS status'] = order.pos_status ? "posted": "Not posted"
                                  orderExportData['Pos Data'] = order.pos_data
                                  orderExportData['Status'] = order.pos_data_status
                                }




                                orderDetails.push(orderExportData)
                            }
                            const fileName = Date.now() + '_order'
                            this.exportExcel(orderDetails, fileName)
                            this.exportType = '';
                        }
                    } else {
                        this.orders = response.data.data;
                        this.totalPages = Math.ceil(response.data.total / this.recordsPerPage)
                        this.totalRecords = response.data.total
                    }

                }
            }).catch((error) => {
              if (error.response && error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            }).finally(() => {
                this.loading = false;
                this.searchingType = '';
            })
        },
        async posRePost(orderid,type=true) {
            this.spinner = orderid;
            const url = `${this.baseUrl}/orders/${orderid}/repost`;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let posreason = ''
            if(!type){
              posreason = this.declineReasons[orderid]
            }
            const payload = {
                'merchant_id': parseInt(this.merchantID),
                'post_status':type,
                'reason':posreason,
                'user_id': this.user.id
            }
            this.axios.post(url, payload, config).then((response) => {
                this.spinner = '';
                if (response.status === 200) {
                    this.getOrders();
                }
                if(!type){
                  document.getElementById(`closedenyPos_${orderid}`).click();
                }
            }).catch((error) => {
                this.spinner = '';
                this.getOrders();
                if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            })
        },
        async checkStatus(id){
          this.statusSpinner = id;
          try{
            const payload =  {
              'reference':id,
              'merchant_id': parseInt(this.merchantID)
            };
            const url = `${this.dashBaseUrl}/check-payment-status`;
            const response = await this.axios.post(url,payload,this.apiRequestConfig())
            if(response.status === 200){
              if(response.data.status == true){
                toast.success("Payment Status "+ response.data.payment_status.payment_status_description);
              }else{
                toast.error("Payment Status " + response.data.payment_status.payment_status_description + " (" + response.data.payment_status.error.code + ") ");
              }
              this.getOrders();
              this.statusSpinner = false;
            }
          }catch(error){
            this.statusSpinner = false;
            if(error.response.status === 401){
              this.$router.push({name:'login'})
            }
          }

        },
        async getBranches() {
            const url = this.baseUrl + "/branch?merchant_id=" + this.merchantID;
            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    if (response.data.data !== null) {
                        const stores = response.data.data;
                        this.stores = stores;
                        this.strsoptions.push({
                                      "value": "",
                                      "name": "All Stores ("+ stores.length +")"
                        })
                        for (let i = 0; i < stores.length; i++) {
                            this.strsoptions.push({
                                "value": stores[i].id,
                                "name": stores[i].branch_name
                            })
                        }
                    }
                }
            }).catch((error) => {
              if (error.response && error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            })

        },
        getItem(item) {
            const OrderStatus = [];
            if (this.itemPosId !== '') {
                const orderItems = item.items;
                this.orderItem = [];
                for (let i = 0; i < orderItems.length; i++) {
                    const pposId = orderItems[i].pos_id;
                    if (pposId.trim() == this.itemPosId.trim()) {
                        OrderStatus.push(orderItems[i]);
                    } else {
                        for (let x = 0; x < orderItems[i].sub_items.length; x++) {
                            const sposId = orderItems[i].sub_items[x].pos_id;
                            if (sposId.trim() == this.itemPosId.trim()) {
                                OrderStatus.push({
                                    name: `${orderItems[i].sub_items[x].name} (${orderItems[i].name})`
                                });
                            }
                        }
                    }
                }
            }
            this.orderItem = OrderStatus;
        },

        async getItems() {
            let url = `${this.baseUrl}/menu_item`;
            url = new URL(url);
            url.searchParams.set('merchant_id', this.merchantID);
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.axios.get(url, config).then((response) => {
                this.menuItems = response.data.data
                for(let x=0; x< this.menuItems.length; x++){
                  this.productOptions.push({
                    "value": this.menuItems[x].menu_item.id,
                    "name":this.menuItems[x].menu_item.item_name
                  })

                }
                // this.productOptions
            }).catch((error) => {
              if (error.response && error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            })
        },
        onPageChange(page) {
            this.page = page
            this.getOrders();
        },
        onChangeRecordsPerPage() {
            const payload = {
              "type":"orders",
              "size":this.recordsPerPage
            }
            this.$store.commit("setPageLimit",payload)
            // setPageLimit
            this.getOrders();
            // ds
        }
    },
    mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>
<style scoped>
</style>
